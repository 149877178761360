import React, { useState } from "react"
import Layout, { ThreeCols } from "../components/layout"
import {
  Avantages,
  Spacer,
  Title,
  Input,
  Radios,
  DateInput,
} from "../components/banners"
import { createMember } from "../actions"
import { useForm } from "../hooks"
import { validEmail, validString } from "../utils/utils"
import { Submitter } from "../components/submitter"

const Form = () => {
  const validate = (s, _errors) => {
      if (!validEmail(s.email)) { _errors.email = "Veuillez saisir une adresse email valide. Nous ne la communiquerons jamais à un tiers." }
      if (!validString(s.pwd) || s.pwd.length < 6) { _errors.pwd = "Veuillez choisir un mot de passe d'au moins 6 caractères." }
      if (s.pwd != s.pwd2) { _errors.pwd2 = "Les mots de passe doivent être identiques." }
      if (!validString(s.firstname)) { _errors.firstname = "Veuillez saisir votre prénom." }
      if (!validString(s.lastname)) { _errors.lastname = "Veuillez saisir votre nom." }
      if (!validString(s.phone)) { _errors.phone = "Veuillez saisir votre numéro de téléphone. Nous ne l'utiliserons qu'en cas de problème avec votre commande." }
  }

  const [u, submit, errors, s] = useForm(validate, createMember, {sex: "F", offers: "Y"})

  return (
    <div style={{ border: "1px solid black", padding: "15px 30px" }}>
      <Title>Créer mon compte</Title>
      <Spacer v="2rem" />
      <Input label="Email" {...u("email")} />
      <Spacer v="1.5rem" />
      <Input
        label="Mot de passe (6 caractères minimum)"
        type="password"
        {...u("pwd")}
      />
      <Spacer v="1.5rem" />
      <Input
        label="Confirmation du mot de passe"
        type="password"
        {...u("pwd2")}
      />
      <Spacer v="2.5rem" />
      <Radios
        buttons={[
          { val: "F", label: "Madame" },
          { val: "M", label: "Monsieur" },
        ]}
        {...u("sex")}
      />
      <Spacer v="1.5rem" />
      <Input label="Prénom" {...u("firstname")} />
      <Spacer v="1.5rem" />
      <Input label="Nom" {...u("lastname")} />
      <Spacer v="1.5rem" />
      <DateInput label="Date de naissance" {...u("dob")} />
      <Spacer v="1.5rem" />
      <Input
        label="Téléphone"
        subLabel="Utilisé uniquement en cas de problème avec votre commande"
        {...u("phone")}
      />
      <Spacer v="1.5rem" />
      <div style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
        Je souhaite être informé{s.sex === "F" && "e"} des offres spéciales
      </div>
      <Radios
        name="offers"
        buttons={[
          { val: "Y", label: "Oui" },
          { val: "N", label: "Non" },
        ]}
        {...u("offers")}
      />
      <Spacer v="1.5rem" />
      <Submitter error={errors.top} action={submit} style={{width: '100%'}}>Valider</Submitter>
    </div>
  )
}

export default () => {
  return (
    <Layout>
      <ThreeCols><Form /></ThreeCols>
      <Spacer v="6rem" />
      <Avantages />
    </Layout>
  )
}
